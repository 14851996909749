// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cadastro-obrigado-js": () => import("./../src/pages/cadastro/obrigado.js" /* webpackChunkName: "component---src-pages-cadastro-obrigado-js" */),
  "component---src-pages-checkout-js": () => import("./../src/pages/checkout.js" /* webpackChunkName: "component---src-pages-checkout-js" */),
  "component---src-pages-curso-js": () => import("./../src/pages/curso.js" /* webpackChunkName: "component---src-pages-curso-js" */),
  "component---src-pages-digitalocean-js": () => import("./../src/pages/digitalocean.js" /* webpackChunkName: "component---src-pages-digitalocean-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-limitado-js": () => import("./../src/pages/limitado.js" /* webpackChunkName: "component---src-pages-limitado-js" */),
  "component---src-pages-linode-js": () => import("./../src/pages/linode.js" /* webpackChunkName: "component---src-pages-linode-js" */),
  "component---src-pages-listadeespera-js": () => import("./../src/pages/listadeespera.js" /* webpackChunkName: "component---src-pages-listadeespera-js" */),
  "component---src-pages-videos-video-01-js": () => import("./../src/pages/videos/video01.js" /* webpackChunkName: "component---src-pages-videos-video-01-js" */),
  "component---src-pages-videos-video-02-js": () => import("./../src/pages/videos/video02.js" /* webpackChunkName: "component---src-pages-videos-video-02-js" */),
  "component---src-pages-videos-video-03-js": () => import("./../src/pages/videos/video03.js" /* webpackChunkName: "component---src-pages-videos-video-03-js" */),
  "component---src-pages-webinario-js": () => import("./../src/pages/webinario.js" /* webpackChunkName: "component---src-pages-webinario-js" */)
}

